import React, { useEffect, useState, useRef } from "react";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Editor } from 'primereact/editor';
import { ProgressSpinner } from "primereact/progressspinner";
import { Rating } from "primereact/rating";
import { ClasseEleveService } from "../../service/ClasseEleveService";
import { EvaluationService } from "../../service/EvaluationService";
import { PersonnelMatiereClasseService } from "../../service/PersonnelMatiereClasseService";
import { useHistory } from "react-router-dom";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../css/TabView.css';
import { SeanceService } from "../../service/SeanceService";
import { ProgressionService } from "../../service/ProgressionService";

import { Dialog } from "primereact/dialog";
import { AppelNumeriqueService } from "../../service/AppelNumeriqueService";
import { ToggleButton } from 'primereact/togglebutton';
import { Message } from 'primereact/message';
import { Toast } from "primereact/toast";
import { FileService } from "../../service/FileService";
import { FileUpload } from "primereact/fileupload";
import { PeriodeService } from "../../service/PeriodeService";
import { ProgressionSeanceService } from "../../service/ProgressionSeanceService";

const DashBoardProfesseur = () => {

    let initProgressionSeance = {
        seanceId: null,
        detailProgressions: [],
        duree: 0,
        dureeTotale: 0,
        position: 0,
        observations: null,
        attachmentUrl: null

    }
    const emptyEleveDto = { eleves: [] };
    const personnelId = sessionStorage.getItem('CandidatId');
    const [classes, setClasses] = useState([]);
    const [classesMatieres, setClasseMatieres] = useState([]);
    const [classesMatieresFinal, setClasseMatieresFinal] = useState([]);
    const [classe, setClasse] = useState(null);
    const [distinctClasses, setDistinctClasses] = useState([]);
    const [pieOptions, setPieOptions] = useState(null);
    const [hidden, setHidden] = useState("hidden");
    const [progressionSeanceBtnDisable, setProgressionSeanceBtnDisable] = useState(true);
    const [hiddenSpinner, setHiddenSpinner] = useState("");
    const [effectif, setEffectif] = useState(0);
    const [seancesProf, setSeancesProf] = useState([]);
    const [openAppelFlag, setOpenAppelFlag] = useState(false);
    const [openProgressionFlag, setOpenProgressionFlag] = useState(false);
    const [elevesDto, setElevesDto] = useState(emptyEleveDto);
    const [heureSaving, setHeureSaving] = useState('');
    const [iterationCountStyle, setIterationCountStyle] = useState({ '': '' });
    const [animateClass, setAnimateClass] = useState('');
    const [presentCmpt, setPresentCmpt] = useState(0);
    const [absentCmpt, setAbsentCmpt] = useState(0);
    const [responseMessage, setResponseMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [hideMessage, setHideMessage] = useState('hidden');
    const [appelIsAlreadySaveClass, setAppelIsAlreadySaveClass] = useState('');
    const [message, setMessage] = useState('');
    const [progression, setProgression] = useState(null);
    const [periodes, setPeriodes] = useState([]);
    const [moisList, setMoisList] = useState([]);
    const [progressionSeance, setProgressionSeance] = useState(initProgressionSeance);
    const [details, setDetails] = useState([]);
    const [text, setText] = useState('');
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const [selectedProgressions, setSelectedProgressions] = useState([]);
    const [fileSelected, setFileSelected] = useState(null);
    const [infoProgSeance, setInfoProgSeance] = useState({});
    const [color, setColor] = useState('green');
    const [hiddenAttachment, setHiddenAttachment] = useState('hidden');
    const [attachmentName, setAttachmentName] = useState('');
    let history = useHistory();

    const chooseOptions = { label: 'Joindre un fichier ...', icon: 'pi pi-fw pi-plus' };
    const uploadOptions = { className: 'hidden' };
    const cancelOptions = { label: 'Annuler', icon: 'pi pi-times', className: 'p-button-danger' };

    const toast = useRef(null);
    const fileUploadRef = useRef(null);

    let progressionSeanceToSaving = initProgressionSeance;
    let flatFileMsg = false;

    const pieData = {
        labels: ['heures enseignées', 'heures restantes', 'Absences'],
        datasets: [
            {
                data: [1, 48, 1],
                backgroundColor: [
                    "#32CD32",
                    "#36A2EB",
                    "#FF6384"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }
        ]
    };

    const applyLightTheme = () => {
        const pieOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            }
        };
        setPieOptions(pieOptions)
    }
    useEffect(() => {
        history.push("/home/professionnelle");
    }, [history]);

    useEffect(() => {

    }, [flatFileMsg])
    // Customiser le menu du coposant editor
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    }

    const editorHeader = renderHeader();

    function formatDate(date) {
        const date1 = (date == null ? '' : date.split('T'));
        if (date1) {
            date = new Date(date1[0]);
            let heure = date1[1].split('Z');
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear()
            ].join('/') + ' à ' + heure[0];
        } else
            return 'N/A';
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    const displayInfos = (e) => {
        let index = e.target.value;
        let indexTab = index.split(',');
        let evaluationService = new EvaluationService();
        setHidden('hidden');
        setClasseMatieres([]);
        for (let i = 0; i < indexTab.length; i++) {
            if (i == 0) {
                setClasse(classes[[indexTab[i]]].classe);
                let classeEleveService = new ClasseEleveService();
                classeEleveService.getEffectifByClasseAndAnnee(classes[indexTab[i]].classe.id, sessionStorage.getItem('AnneEncours')).then(
                    res => setEffectif(res)
                )
            }
            evaluationService.getCountByClasseAndMatiere(sessionStorage.getItem("AnneEncours"), classes[indexTab[i]].classe.id, classes[indexTab[i]].matiere.id).then(res => {
                let _classe = { ...classes[indexTab[i]] };
                _classe.nbreEval = res;
                classesMatieres.push(_classe);
                console.log(classesMatieres.length)
                //Pour afficher l'ensemble du detail des matieres enseignées on ouvre la zone d'affichage à la fin des traitements
                if (i == indexTab.length - 1) {
                    setHidden("");
                    setClasseMatieresFinal(classesMatieres);
                }

            });
        }
    }

    const detailDisplay = (row, index) => {
        return (
            <div className="col-12 grid" key={index}>
                <div className="col-12 text-lg font-bold">
                    <span className="font-bold text-lg p-success p-tag">Matière enseignée : </span> {row.matiere ? row.matiere.libelle : "N/A"}
                </div>
                <div className="col-4 text-lg border-top-1 border-400 pl-8">
                    <div className="font-semibold my-2">
                        <span className="text-base">Nombre d'heures total:</span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base">Heures Enseignées:</span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Evaluation(s) Effectuée(s):</span> <Badge value={row.nbreEval !== '' ? '' + row.nbreEval + '' : "N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Nombre de séances:</span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Séances effectuées: </span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base ">Absences: </span> <Badge value={"N/A"} severity="info" />
                    </div>
                    <div className="font-semibold my-2">
                        <span className="text-base inline">Notation: </span> <Rating className="inline ml-2" value={0} readOnly={true} />
                    </div>

                </div>
                <div className="col-8 border-top-1 border-400">

                    <Chart type="pie" data={pieData} options={pieOptions} style={{ width: '20%' }} />

                </div>
            </div>
        )
    }
    const horaireBody = (rowData) => {
        return (
            <div className={`m-0 py-3 pl-2 font-semibold ${rowData.isClassEnded}`}>
                {rowData.heureDeb} - {rowData.heureFin}
            </div>
        )
    }

    const onHideListeAppel = () => {
        setOpenAppelFlag(false);
        setElevesDto(emptyEleveDto);
        setAbsentCmpt(0);
        setPresentCmpt(0);
        setHeureSaving('');
        setAnimateClass('');
        setIterationCountStyle({ '': '' });
        setHideMessage('hidden');
        // Mettre à jour la liste des emplois du temps pour raffraichir le bouton d'appel
        const seanceService = new SeanceService();
        seanceService.getListByDateAndProf(new Date().toISOString().split('T')[0], sessionStorage.getItem('CandidatId')).then((resp) => {
            setSeancesProf(resp);
            console.log(resp)
        })
        console.log('on hide');
    }

    const onHideProgression = () => {
        setOpenProgressionFlag(false);
        setHiddenAttachment('hidden');
        setProgressionSeance(initProgressionSeance);
        setSelectedProgressions([]);
        setProgressionSeanceBtnDisable(true);
        setText('');
    }

    const openListeAppel = (e) => {
        console.log('open liste appel');
        const appelService = new AppelNumeriqueService();
        appelService.getListAppel(e.target.id, e.target?.attributes['position']?.value).then(res => {
            let now = new Date();
            res.heureDebutAppel = now.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
            res.personnelId = personnelId;
            res.duree = e.target?.attributes['duree']?.value
            setElevesDto(res);
            setPresentCmpt(res.presenceCmpt);
            setAbsentCmpt(res.absenceCmpt);
            setHeureSaving(formatDate(res.dateAppel));
            console.log(res);
        }).catch((error) => {
            console.log(error);
        })

        setOpenAppelFlag(true);
    }

    function getfileName(uri) {
        // Sépare l'URI en segments en utilisant le slash (/) comme délimiteur
        const segments = uri.split('/');

        // Retourne le dernier élément du tableau, qui est le fichier abc.txt
        return segments.pop();
    }

    const openProgression = (e) => {
        console.log('open progression')

        let _progressionSeance = { ...progressionSeance };
        _progressionSeance.seanceId = e.target?.attributes['sid']?.value;
        _progressionSeance.duree = e.target?.attributes['duree']?.value;
        _progressionSeance.dureeTotale = e.target?.attributes['dureetotale']?.value;
        _progressionSeance.position = e.target?.attributes['position']?.value;
        let _infoProgSeance = { ...infoProgSeance };
        _infoProgSeance.classelib = e.target?.attributes['classelib']?.value;
        _infoProgSeance.horaire = e.target?.attributes['horaire']?.value;
        setInfoProgSeance(_infoProgSeance);
        setProgressionSeance(_progressionSeance);
        setHiddenAttachment('hidden');
        setProgressionSeanceBtnDisable(true);
        let progression = new ProgressionService();
        const periodeService = new PeriodeService();

        periodeService.getList().then(res => {
            setPeriodes(res);
            setMoisList(res.filter(res => res.periodicite.id === 1))
        })

        progression.getBySeance(e.target?.attributes['sid']?.value).then((res) => {
            // console.log(res);
            setProgression(res);
            setDetails(res.datas);
            setMessage('');
            const progressionSeanceService = new ProgressionSeanceService();
            progressionSeanceService.getBySeanceAndPosition(e.target?.attributes['sid']?.value, e.target?.attributes['position']?.value).then(resps => {
                let _detailsInBd = [];
                // console.log(res.datas)        
                // console.log(resps.detailProgressions)        
                if (res.datas != null && res.datas.length > 0 && resps.detailProgressions != null && resps.detailProgressions.length > 0) {
                    _detailsInBd = res.datas.filter(s => resps.detailProgressions.includes(s.id))
                    // console.log(_detailsInBd);
                }
                setSelectedProgressions(_detailsInBd);
                let _progressionSeance2 = { ..._progressionSeance };
                let content = resps.observations != null ? resps.observations : '';
                _progressionSeance2.observations = content;
                _progressionSeance2.attachmentUrl = resps.attachmentUrl;
                _progressionSeance2.detailProgressions = resps.detailProgressions != null ? resps.detailProgressions : [];
                setProgressionSeance(_progressionSeance2);
                console.log('progressionSeance', _progressionSeance2);
                setText(content);
                if (resps.attachmentUrl) {
                    setAttachmentName(getfileName(resps.attachmentUrl))
                    setHiddenAttachment('');
                }
            })
        }).catch((error) => {
            console.log(error?.message)
        })

        setOpenProgressionFlag(true);
    }

    const appelBody = (rowData) => {
        let classLibelle = 'p-button-outlined p-button-secondary';
        if (rowData.appelAlreadyExist)
            classLibelle = 'p-button-success';
        return (
            <div>
                <Button id={rowData.id} disabled={!rowData.isVerrou} position={rowData.position} duree={rowData.duree} className={`${classLibelle} ml-2`}
                    label="Appel" severity="success" onClick={(e) => openListeAppel(e)} />
                <Button id={'pgs_' + rowData.id} sid={rowData.id} classelib={rowData?.classe?.libelle} horaire={rowData.heureDeb + ' - ' + rowData.heureFin}
                    position={rowData.position} dureetotale={rowData.dureeTotale} duree={rowData.duree} className={`${classLibelle} ml-2`} label="Progression"
                    severity="success" onClick={(e) => openProgression(e)} />
            </div>
        )
    }

    const saveAppel = () => {
        const appelService = new AppelNumeriqueService();
        // console.log(elevesDto);
        if (elevesDto.personnelId != null) {
            appelService.save(elevesDto).then(resp => {
                const dateObj = new Date(Date.parse(resp.headers['date-save']));
                setHeureSaving(dateObj.toLocaleDateString('fr-FR', options));
                setIterationCountStyle({ animationIterationCount: '9', 'color': 'green' });
                setAnimateClass('fadeout animation-duration-500 animation-iteration-9');
                setHideMessage('');
                setResponseMessage(resp.data);
                setSeverity('success');
            }).catch((error) => {
                // console.log(error);
                setAnimateClass('fadeout animation-duration-500 animation-iteration-9');
                setIterationCountStyle({ animationIterationCount: '9', 'color': 'red' });
                setHideMessage('');
                setSeverity('error');
                setResponseMessage(error.response.data);
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.response.data, life: 15000 });
            })
        }
    }

    const appelFooter = () => {
        return <div className="p-3 surface-200">
            <Button className="p-button-success" label="Enregistrer" onClick={saveAppel}></Button>
            <Message style={iterationCountStyle} className={'ml-3 ' + animateClass + ' ' + hideMessage} severity={severity} text={responseMessage} />
        </div>
    }

    const progressionSeanceValidator = () => {
        let flat = progressionSeance.detailProgressions.length > 0 && progressionSeance.seanceId && progressionSeance.duree && progressionSeance.dureeTotale;
        return flat;
    }

    let buildFilePrefixeUri = () => {
        let _annee = sessionStorage.getItem('AnneEncours');
        let _ecole = sessionStorage.getItem('EcoleID');
        let _module = 'progressions';
        let _niveau = progression?.branche?.id;
        let uriTab = [];
        let uri = '';
        if (_ecole) {
            uriTab.push(_ecole)
            // console.log('ecole ', _ecole);
        }
        if (_annee) {
            uriTab.push(_annee)
            // console.log('_annee ', _annee);
        }
        if (_module) {
            uriTab.push(_module)
            // console.log('_module ', _module);
        }
        if (_niveau) {
            uriTab.push(_niveau)
            // console.log('_niveau ', _niveau);
        }
        uri = uriTab.join('/');
        // console.log('tab ', uriTab);
        // console.log('build prefixe ::: ' + uri)
        return uri;
    }

    const launchFileSavedProcess = async () => {
        fileUploadRef.current.upload();
        return true;
    }

    const saveProgressionSeance = async () => {
        if (progressionSeanceValidator()) {
            // let urlFile = null;
            // console.log(fileUploadRef.current)
            if (fileUploadRef.current) {
                if (fileUploadRef.current?.files && fileUploadRef.current?.files?.length > 1) {
                    setColor('red');
                    setMessage("Veuillez selectioner un seul fichier");
                    return
                }
                // Lancer la method onUpload du composant FileUpdate
                await launchFileSavedProcess();

            } else {
                console.log('fileUploadRef.current est nul');
            }
            // onUpload(fileSelected);


        } else {
            console.log('Erreur, la validation a échoué');
            toast.current.show({ severity: 'error', summary: 'Enregistrement', detail: 'Erreur, la validation des données a échoué', life: 15000 });
            // console.log(progressionSeance);
        }
    }

    const progressionFooter = () => {
        return (<div>
            <Button className="p-button-success" disabled={progressionSeanceBtnDisable} label="Enregistrer" onClick={() => saveProgressionSeance()}></Button>
            <Button className="p-button-warning" label="Fermer" onClick={() => onHideProgression()}></Button>
        </div>)
    }

    const onCheckToogleBtn = (e) => {
        let _elevesDto = { ...elevesDto };
        for (const eleve of _elevesDto.eleves) {
            if (eleve.inscriptionClasseEleveId == e.target.id) {
                if (e.target.value === false) {
                    eleve.presence = 'A';
                    setAbsentCmpt(absentCmpt + 1);
                    setPresentCmpt(presentCmpt - 1);
                } else {
                    eleve.presence = 'P';
                    setAbsentCmpt(absentCmpt - 1);
                    setPresentCmpt(presentCmpt + 1);
                }
                break;
            }
        }
        setElevesDto(_elevesDto);
        setAnimateClass('');
        setIterationCountStyle({ '': '' });
        setHideMessage('hidden');
    }

    const appelHeader = () => {
        return (
            <div>
                <div className="field">
                    <span>Séance ({elevesDto.typeSeance}) de {elevesDto.heureDebutSeance} à {elevesDto.heureFinSeance}</span>
                </div>
                <div className="field">
                    <span>Matiere : {elevesDto.matiereLibelle}</span>
                </div>
                <div className="field">
                    <span>Effectif : {elevesDto.effectif}</span> &#x5B;<span style={{ color: 'green' }}> Présent(s) : {presentCmpt}</span> - <span style={{ color: 'red' }}>Absent(s) : {absentCmpt} </span>&#x5D;
                </div>
                <div className="field">
                    <span>Enseignant : {elevesDto.enseignantNomPrenom}</span>
                </div>
                <div>
                    <small><i>Dernier appel enregistré le :</i><span style={iterationCountStyle} className={animateClass}> {heureSaving}</span></small>
                </div>
            </div>
        )
    }

    const onBasicUpload = () => {
        console.log('coucou file');
    }

    const fullDateFormat = (date) => {
        const jours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const mois = [
            "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
            "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
        if (date == null)
            date = new Date();
        // console.log(jours[date.getDay()]);
        // console.log(date.getDate());
        // console.log(mois[date.getMonth()]);
        // console.log(date.getFullYear());
        return jours[date.getDay()] + ' ' + date.getDate() + ' ' + mois[date.getMonth()] + ' ' + date.getFullYear();
    }

    const progressionInfos = () => {
        return (
            <div className="m-2 grid">
                <div className="col-12"><span className="p-tag p-tag-rounded text-base">{progression?.annee?.libelle} </span> <Button label="Historique" className="hidden" /></div>
                <div className="col-4">
                    <div>Niveau: {progression?.branche?.libelle}</div>
                    <div>Classe : {infoProgSeance?.classelib} </div>
                </div>
                <div className="col-8">
                    <div >Matière: {progression?.matiere?.libelle}</div>
                    <div className="pt-auto pb-0">horaire: {infoProgSeance?.horaire}</div>
                </div>
                
            </div>
        )
    }

    const nomPrenomsEleve = (rowData) => {
        return rowData.nom + ' ' + rowData.prenoms;
    }

    const presence = (rowData) => {
        return <ToggleButton onLabel="P" offLabel="A" id={rowData.inscriptionClasseEleveId} checked={(rowData.presence === 'A' ? false : true)} onChange={(e) => onCheckToogleBtn(e)} />
    }

    const moisBody = (rowData) => {
        return moisList.filter(f => f.id == rowData.mois)[0]?.libelle
    }

    const onSelectedProgression = (selected) => {
        let _arr = selected.map(s => s.id);
        setSelectedProgressions(selected);
        let _progressionSeance = { ...progressionSeance };
        _progressionSeance.detailProgressions = _arr;
        setProgressionSeance(_progressionSeance);
        console.log(_progressionSeance);
        setText(text != null ? text : '');
        setProgressionSeanceBtnDisable(selected.length == 0);
    }

    const onTextEditor = (content) => {
        setText(content);
        let _progressionSeance = { ...progressionSeance };
        _progressionSeance.observations = content;
        setProgressionSeance(_progressionSeance);
        setProgressionSeanceBtnDisable(selectedProgressions.length == 0);
    }

    const onSelectFile = (e) => {
        // fileUploadRef.current.files = [e.files[0]];
        // console.log('files to update');
        // console.log(fileUploadRef.current.files);
        // console.log(e.files[0]);
        // fileUploadRef.current.files = fileUploadRef.current.files;
        // console.log(fileUploadRef.current.files);
        if (e.files) {
            console.log('fichier selectionné')
            setFileSelected(e);
            if (selectedProgressions) {
                // si un fichier est joint on vérifie l'existence de progression selectionnée
                // avant de deverrouiller le bouton d'enregistrement
                setProgressionSeanceBtnDisable(false);
            }
        }

    }


    const onRemove = (e) => {
        console.log('on remove');
        // console.log('fileUploadRef.current.files', fileUploadRef.current.files);
        let _actual_files = fileUploadRef.current.files.filter(f => f.name != e.file.name);
        // console.log('actual ', _actual_files);
        fileUploadRef.current.files = _actual_files
        console.log('ev', e)
    }

    async function buildFormData(files) {
        let formData = new FormData();
        console.log(files[0])
        formData.append('file', files[0]);
        formData.append('fileName', files[0].name);
        formData.append('prefixUri', buildFilePrefixeUri())
        return formData;
    }

    const onUploadFileAndSave = async ({ files }) => {
        // console.log('Fichiers reçus :', files);
        // console.log('Fichiers reçus 2 :', fileSelected);
        let _progressionSeanceToSave = { ...progressionSeance }
        // console.log('_progressionSeanceToSave 1', _progressionSeanceToSave)
        if (files.length > 1) {
            console.error('Veuillez sélectionner un seul fichier.');
            return;
        }
        if (files.length === 1) {

            let formData = await buildFormData(files);
            // formData.append('file', files[0]);
            // formData.append('fileName', files[0].name); // Ajout du nom du fichier

            const fileService = new FileService();
            // let uri = 
            await fileService.sendFile(formData).then(res => {
                // let _progressionSeance = { ...progressionSeance };
                // _progressionSeance.attachmentUrl = res.headers['uri-file'];
                let attachmentUri = res.headers['uri-file'];
                console.log('uri-file', res.headers['uri-file']);
                // _progressionSeanceToSave = { ...progressionSeance }
                _progressionSeanceToSave.attachmentUrl = res?.headers['uri-file'];
                // progressionSeanceToSaving = setAttachment(res);

                console.log('_progressionSeanceToSave B', _progressionSeanceToSave)
                setAttachmentName(getfileName(attachmentUri));
                setHiddenAttachment('');
                setProgressionSeance(_progressionSeanceToSave);
                // _progressionSeanceToSave = progressionSeanceToSaving
                console.log('_progressionSeanceToSave 2', _progressionSeanceToSave)
                // setUriFile(res.headers['uri-file']);
                flatFileMsg = true;
            }).catch((error) => {
                flatFileMsg = false;
                console.error('Erreur lors de l\'upload du fichier :', error.response);
                setColor('red');
                setMessage('Échec de l\'upload du fichier.');
            })
            // return;
        }
        // console.log('Détails du fichier :', files[0]);
        // let urlFile = buildFilePrefixeUri() + fileUploadRef.current.files[0].name;
        // console.log('urlFile', urlFile);


        // if (uri) {
        // console.log('_progressionSeanceToSave 3', _progressionSeanceToSave)
        const progressionSeanceService = new ProgressionSeanceService();
        await progressionSeanceService.handleSave(_progressionSeanceToSave).then(res => {
            console.log('prog saved')
            // console.log('attachmentUri: ', uri);
            if (flatFileMsg) {
                setProgressionSeanceBtnDisable(true);
                setColor('green');
                setMessage('Fichier uploadé avec succès !');
            }
            toast.current.show({ severity: 'success', summary: 'Enregistrement', detail: res, life: 15000 });
        }).catch(error => {
            console.log(error.response);
            toast.current.show({ severity: 'error', summary: 'Enregistrement', detail: error.response, life: 15000 });
        })
        // }
    };

    const downLoadFile = (uri) => {
        console.log(uri)
        const fileService = new FileService();
        fileService.downloadFile(uri).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = uri.split('/').pop(); // Nom du fichier à télécharger
            document.body.appendChild(a);
            a.click();
            a.remove();
            // Libérer l'URL après usage
            window.URL.revokeObjectURL(url);

        }).catch((error) => {
            console.error('Erreur lors du téléchargement:', error);
        })

    }

    const deleteAttachment = () => {
        let _progressionSeance = { ...progressionSeance };
        _progressionSeance.attachmentUrl = null;
        setProgressionSeance(_progressionSeance);
        setAttachmentName('');
        setHiddenAttachment('hidden');
        setProgressionSeanceBtnDisable(false);
    }

    useEffect(() => {
        // liste des classes enseignées par le professeur
        const personnelClasseMatiereService = new PersonnelMatiereClasseService();
        const seanceService = new SeanceService();
        personnelClasseMatiereService.getByProfesseur(sessionStorage.getItem('AnneEncours'), personnelId, sessionStorage.getItem('EcoleID')).then(res => {
            setClasses(res);
            // console.log(res);
            let tabCtrl = [];
            for (let i = 0; i < res.length; i++) {
                let flag = false;
                let indexFlag = -1;
                for (let j = 0; j < tabCtrl.length; j++) {
                    if (res[i].classe.id === tabCtrl[j].id) {
                        flag = true;
                        indexFlag = j;
                    }
                }

                // console.log("--- data before");
                // console.log(tabCtrl);
                // console.log(res[i].classe);
                if (!flag) {
                    // console.log("-> tabnonincludes");
                    let _classe = { ...res[i].classe };
                    tabCtrl.push(res[i].classe);
                    _classe.indexes = i;
                    distinctClasses.push(_classe);
                } else {
                    // console.log("-> tabincludes");
                    let _classe = { ...distinctClasses[indexFlag] };
                    _classe.indexes = _classe.indexes + ',' + i;
                    distinctClasses[indexFlag] = { ..._classe };

                }

            }
            setHiddenSpinner('hidden');
            // console.log(distinctClasses);
            // console.log("--- data fin");
        });

        seanceService.getListByDateAndProf(new Date().toISOString().split('T')[0], sessionStorage.getItem('CandidatId')).then((resp) => {
            console.log(resp);
            setSeancesProf(resp);
        })
        // Appliquer le theme light au graphique
        applyLightTheme();
    }, [distinctClasses])

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card surface-800">
                    <p className="font-bold text-2xl text-white"> Dashboard du professeur</p>
                    <p className="ml-3 font-bold text-lg font-italic text-white"> Bienvenue</p>
                </div>
            </div>

            <div className="card col-12">

                <TabView className="tabview-pouls">
                    <TabPanel className="tabview-header-icon" header="Mes activités" leftIcon="pi pi-calendar">
                        <h6><i><b className="text-green-700">{fullDateFormat()}</b></i></h6>
                        <h4><b>Mon emploi du temps du jour</b></h4>
                        <DataTable value={seancesProf} size="small" responsiveLayout="scroll" emptyMessage="Aucune séance trouvée">
                            <Column body={horaireBody} className="p-0" header="Horaire"></Column>
                            <Column header="Type" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.typeActivite.libelle}</div>} ></Column>
                            <Column header="Matière" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.matiere.libelle}</div>} ></Column>
                            <Column header="Classe" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.classe.libelle}</div>} ></Column>
                            <Column header="Salle" className="p-0" body={(rowData) => <div className={`m-0 py-3 ${rowData.isClassEnded}`}>{rowData.salle.libelle}</div>} ></Column>
                            <Column header="Actions" body={appelBody}></Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel className="tabview-header-icon grid" header="Mes classes" leftIcon="pi pi-home">

                        <div className="p-3 col-12">
                            <ProgressSpinner visible={false} style={{ width: '30px', height: '30px', visibility: hiddenSpinner }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            {distinctClasses.length !== 0 ? distinctClasses.map((elmt, index) => <Button key={index} style={{ visibility: (hiddenSpinner === 'hidden' ? '' : 'hidden') }} value={elmt.indexes} label={elmt.libelle} className="p-button-outlined mr-2 mb-2" onClick={displayInfos} />) : <i className="text-orange-800">Vous n'avez aucune classe à enseigner</i>}
                        </div>

                        <div className={"col-12 card " + hidden}>
                            <div className="grid">
                                <div className="col-3 font-bold">
                                    <span className="text-lg p-tag">Classe : </span> {classe ? classe.libelle : "N/A"}
                                </div>

                                <div className="col-3 font-bold">
                                    <span className="text-lg p-tag">Effectif : </span> {effectif}
                                </div>
                                {classesMatieresFinal.map((elmt, index) => detailDisplay(elmt, index))}
                            </div>
                        </div>

                    </TabPanel>
                    <TabPanel header="Mes messages" disabled={true} className="tabview-header-icon" rightIcon="pi pi-envelope">
                        <h3>Non disponible.</h3>
                    </TabPanel>
                    <TabPanel header="Mes Statistiques" disabled={true} className="tabview-header-icon" leftIcon="pi pi-chart-line">
                        <h3>Non disponible.</h3>
                    </TabPanel>
                </TabView>
            </div>
            <Dialog visible={openAppelFlag} style={{ width: '600px' }} onHide={onHideListeAppel}>
                <Toast ref={toast} />
                <div>
                    <DataTable value={elevesDto.eleves} size="small" header={appelHeader} footer={appelFooter} responsiveLayout="scroll" emptyMessage="Aucun élève trouvé">
                        <Column field={nomPrenomsEleve} header='Nom & Prénoms'></Column>
                        <Column field={presence} header='Présence'></Column>
                    </DataTable>
                </div>
            </Dialog>
            <Dialog visible={openProgressionFlag} style={{ width: '90%' }} footer={progressionFooter} onHide={onHideProgression}>
                <Toast ref={toast} />
                <div className="grid">
                    <div className="grid col-6">
                        <div className="col-12">
                            <DataTable dataKey="id" value={details} selection={selectedProgressions} emptyMessage="Progresion non encore définie" onSelectionChange={e => { onSelectedProgression(e.value); }} header={progressionInfos} size="small" scrollable scrollHeight="400px" responsiveLayout="scroll">
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} ></Column>
                                <Column field="periode.libelle" header="Période"></Column>
                                <Column body={moisBody} header="Mois"></Column>
                                <Column field="semaine" header="Semaine"></Column>
                                <Column field="numLecon" Style={{ width: '5rem' }} header="numéro titre"></Column>
                                <Column field="titre" header="Titre de la léçon"></Column>
                                <Column field="heure" header="Nbre Heure"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="font-bold m-1 p-3 shadow-2">Observations</div>
                        <Editor headerTemplate={editorHeader} style={{ height: '320px' }} value={text} onTextChange={(e) => onTextEditor(e.htmlValue)} />
                        <div className="col-12 shadow-1">
                            <div className={hiddenAttachment + "hidden flex flex-row-reverse"}>
                                <Button icon="pi pi-times" className={hiddenAttachment + " p-button-danger ml-1"} onClick={() => deleteAttachment()} />
                                <Button style={{ 'maxWidth': '300px', 'maxHeight': '35px' }} icon='pi pi-paperclip' value={progressionSeance.attachmentUrl}
                                    className={hiddenAttachment + " p-button-outlined p-button-help"} label={attachmentName} onClick={(e) => downLoadFile(e?.target?.value)} />
                            </div>
                            <FileUpload name="demo" ref={fileUploadRef} maxFileSize="2000000" multiple={false} uploadOptions={uploadOptions}
                                cancelOptions={cancelOptions} chooseOptions={chooseOptions} customUpload uploadHandler={onUploadFileAndSave}
                                onSelect={(e) => onSelectFile(e)} invalidFileSizeMessageSummary="Attention : " onRemove={(e) => onRemove(e)}
                                invalidFileSizeMessageDetail="La taille maximale du fichier doit être inferieure à 2 Mo" />
                            {message && <p className="font-bold" style={{ 'color': color }}>{message}</p>}
                        </div>
                    </div>
                </div>
            </Dialog>

        </div>
    );
}


/* const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DashBoardProfesseur, comparisonFn); */
export default DashBoardProfesseur;
